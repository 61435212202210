import React, { useState } from 'react'
import { Grid, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useScreenWidth from '../../../hooks/useScreenWidth'
import useStaticImage from '../../../hooks/useStaticImage'

import information from './information.json'

import { GatsbyImage } from 'gatsby-plugin-image'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import './styles.scss'

const GridSlider = () => {
  const [getImage] = useStaticImage()
  const { isMobile } = useScreenWidth()

  const [activeTab, setActiveTab] = useState('allCases')
  const [activeTitleTab, setActiveTitleTab] = useState('Todos os cases')
  const [activeDropDown, setActiveDropDown] = useState('close')

  const minLengthForColumnFill = 6
  const sliderLengths = information.reduce((acc, item) => {
    const curCategory = item.category

    if (!acc[curCategory]) {
      acc[curCategory] = 0
    }

    acc[curCategory] += 1

    if (curCategory !== 'allCases') {
      acc.allCases ? (acc.allCases += 1) : (acc.allCases = 1)
    }

    return acc
  }, {})

  const handleDrop = () => {
    activeDropDown === 'close' ? setActiveDropDown('open') : setActiveDropDown('close')
  }

  const handleTab = (tab) => setActiveTab(tab)

  const handleActiveClassTab = (tab) => {
    return activeTab === tab ? 'tabs__item--active' : 'tabs__item--disable'
  }

  const handleActiveClassContainer = (tab) => {
    const dispositionType =
      sliderLengths[tab] > minLengthForColumnFill || isMobile ? 'column' : 'row'

    return `disposition--${dispositionType} ${tab} ${activeTab === tab ? 'active' : ''}`
  }

  const GridSliderComponent = ({ slideAmount, children }) => {
    const slideDisposition =
      slideAmount > minLengthForColumnFill || isMobile ? 'column' : 'row'

    return (
      <Swiper
        allowTouchMove={!!isMobile}
        spaceBetween={20}
        slidesPerGroup={2}
        slidesPerView={2}
        grid={{
          rows: 3,
          fill: slideDisposition,
        }}
        navigation={{}}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>'
          },
        }}
        modules={[Navigation, Grid, Pagination]}
        breakpoints={{
          500: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
            grid: {
              rows: 3,
              fill: slideDisposition,
            },
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
            grid: {
              rows: 3,
              fill: slideDisposition,
            },
          },
        }}
        className="mySwiper"
      >
        {children}
      </Swiper>
    )
  }

  const renderMenu = () => {
    const infos = [
      {
        slider: 'allCases',
        title: 'Todos os cases',
      },
      {
        slider: 'webAndMobile',
        title: 'Projetos Web e Mobile',
      },
      {
        slider: 'outsourcing',
        title: 'Outsourcing',
      },
      {
        slider: 'innovation',
        title: 'Inovação',
      },
      {
        slider: 'aiLabs',
        title: 'AI Labs',
      },
    ]
    return infos.map((item) => {
      return (
        <li
          className={handleActiveClassTab(item.slider)}
          onClick={() => handleTab(`${item.slider}`)}
          onKeyDown={() => handleTab(`${item.slider}`)}
          role="none"
          key={item.slider}
        >
          <p>
            {item.title}
            <span></span>
          </p>
        </li>
      )
    })
  }

  const renderMenuMobile = () => {
    const infos = [
      {
        slider: 'allCases',
        title: 'Todos os cases',
        active: activeTab === 'allCases',
      },
      {
        slider: 'webAndMobile',
        title: 'Projetos Web e Mobile',
        active: activeTab === 'webAndMobile',
      },
      {
        slider: 'outsourcing',
        title: 'Outsourcing',
        active: activeTab === 'outsourcing',
      },
      {
        slider: 'innovation',
        title: 'Inovação',
        active: activeTab === 'innovation',
      },
      {
        slider: 'aiLabs',
        title: 'AI Labs',
        active: activeTab === 'aiLabs',
      },
    ]

    return infos.map((item) => {
      return (
        <li
          style={{ color: activeTab === item.slider && '#F05A22' }}
          className="mobile-tabs__list--item"
          onClick={() => {
            setActiveTab(item.slider)
            setActiveTitleTab(item.title)
            setActiveDropDown('close')
          }}
          onKeyDown={() => {
            setActiveTab(item.slider)
            setActiveTitleTab(item.title)
            setActiveDropDown('close')
          }}
          role="none"
          key={item.slider}
        >
          <p>{item.title}</p>
        </li>
      )
    })
  }

  return (
    <section className="gridslider">
      <div className={`gridslider__container`}>
        <div className="gridslider__tabs">
          <ul className="tabs">{renderMenu()}</ul>

          <div style={{ position: 'relative' }}>
            <div className="mobile-tabs" onClick={handleDrop} onKeyDown={handleDrop}>
              {activeTitleTab}
              <span
                className={`mobile-tabs__caret ${
                  activeDropDown === 'open' && 'mobile-tabs__caret--opened'
                }`}
              />
            </div>
            {activeDropDown === 'open' && (
              <div className="mobile-tabs__list">{renderMenuMobile()}</div>
            )}
          </div>
        </div>
        <div className={handleActiveClassContainer('allCases')}>
          <GridSliderComponent slideAmount={sliderLengths.allCases}>
            {information
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.name === item.name),
              )
              .map((item) => {
                return (
                  <SwiperSlide key={item.image}>
                    <a title={item.name} href={item.text.link ?? '#'} target="_blank">
                      <div className="gridslider__item">
                        <GatsbyImage image={getImage(item.image)} alt={`${item.name}`} />
                        <div className="item-hover">
                          <div>
                            <p>{item.name}</p>
                            <p>{item.text.content}</p>
                            <p>Veja mais</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('webAndMobile')}>
          <GridSliderComponent slideAmount={sliderLengths.webAndMobile}>
            {information
              .filter((item) => item.category === 'webAndMobile')
              .map((item) => {
                return (
                  <SwiperSlide key={item.image}>
                    <a title={item.name} href={`${item?.text?.link}`} target="_blank">
                      <div className="gridslider__item">
                        <GatsbyImage image={getImage(item.image)} alt={`${item.name}`} />
                        <div className="item-hover">
                          <div>
                            <p>{item.name}</p>
                            <p>{item.text.content}</p>
                            <p>Veja mais</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('outsourcing')}>
          <GridSliderComponent slideAmount={sliderLengths.outsourcing}>
            {information
              .filter((item) => item.category === 'outsourcing')
              .map((item) => {
                return (
                  <SwiperSlide key={item.image}>
                    <a title={item.name} href={`${item?.text?.link}`} target="_blank">
                      <div className="gridslider__item">
                        <GatsbyImage image={getImage(item.image)} alt={`${item.name}`} />
                        <div className="item-hover">
                          <div>
                            <p>{item.name}</p>
                            <p>{item.text.content}</p>
                            <p>Veja mais</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('innovation')}>
          <GridSliderComponent slideAmount={sliderLengths.innovation}>
            {information
              .filter((item) => item.category === 'innovation')
              .map((item) => {
                return (
                  <SwiperSlide key={item.image}>
                    <a title={item.name} href={`${item?.text?.link}`} target="_blank">
                      <div className="gridslider__item">
                        <GatsbyImage image={getImage(item.image)} alt={`${item.name}`} />
                        <div className="item-hover">
                          <div>
                            <p>{item.name}</p>
                            <p>{item.text.content}</p>
                            <p>Veja mais</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
        <div className={handleActiveClassContainer('aiLabs')}>
          <GridSliderComponent slideAmount={sliderLengths.aiLabs}>
            {information
              .filter((item) => item.category === 'aiLabs')
              .map((item) => {
                return (
                  <SwiperSlide key={item.image}>
                    <a title={item.name} href={`${item?.text?.link}`} target="_blank">
                      <div className="gridslider__item">
                        <GatsbyImage image={getImage(item.image)} alt={`${item.name}`} />
                        <div className="item-hover">
                          <div>
                            <p>{item.name}</p>
                            <p>{item.text.content}</p>
                            <p>Veja mais</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </SwiperSlide>
                )
              })}
          </GridSliderComponent>
        </div>
      </div>
    </section>
  )
}
export default GridSlider
